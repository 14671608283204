#loading{
    background-image: url("./common/images/loading.svg");
    background-size: 28px 28px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: none;
    animation: loading 2.5s linear infinite;
}
@keyframes loading
{
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

@-webkit-keyframes loading /*Safari and Chrome*/
{
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

.dialog-toast {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    padding: 4px 10px;
    transform: translate(-50%, -50%);
    transition: opacity 0.25s linear 0.75s;
    border-radius: 4px;
}